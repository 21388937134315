import apiClient from '@/services/axios'

const create = async (data) => {
    const res = await apiClient.post('/api/visits', data, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const getVisitFilter = async (date, status, patientName, sortBy, includeDoctorDetail, includeAnamnesisDetail, doctorId) => {
    const res = await apiClient.get(`/api/visits?date[eq]=${date}&status[eq]=${status}&patient_name[like]=${patientName}&sort_by=${sortBy}&include_doctor_detail=${includeDoctorDetail}&include_anamnesis_detail=${includeAnamnesisDetail}&doctor_id[eq]=${doctorId}`, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const getPatientVisit = async (patientId) => {
    const res = await apiClient.get(`/api/visits/${patientId}`, {})
    if (res) {
        return res
    } else {
        return null
    }

}

const nurseCheckup = async (data) => {
    const res = await apiClient.post('/api/visits/nurse-checkup', data, {})
    if (res) {
        return res
    } else {
        return null
    }

}

const uploadDokumenPenunjang = async (data) => {
    const res = await apiClient.post('/api/visits/nurse-checkup/documents', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    // console.log("res", res)
    if (res) {
        return res
    } else {
        return null
    }
}

const getDokumenPenunjang = async (visitId, documentId) => {
    try {
        const res = await apiClient.get(`/api/documents?&document_id=${documentId}`, {
            responseType: 'blob',
        });
        // console.log("res", res);
        if (res) {
            return res;
        } else {
            return null;
        }

    } catch (error) {
        console.error("Error fetching document:", error);
        return null;
    }
}

const getDokumenPenunjangByDocId = async (documentId) => {
    try {
        const res = await apiClient.get(`/api/documents?document_id=${documentId}`, {
            responseType: 'blob',
        });
        // console.log("res", res);
        if (res) {
            return res;
        } else {
            return null;
        }

    } catch (error) {
        console.error("Error fetching document:", error);
        return null;
    }
}

const startDoctorCheckUp = async (data) => {
    const res = await apiClient.post('/api/visits/doctor-checkup/start', data, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const addAudio = async (data) => {
    const res = await apiClient.post('/api/visits/doctor-checkup/upload-audio', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    console.log("response audio", res)
    if (res) {
        return res
    } else {
        return null
    }
}

const saveDoctorNote = async (data) => {
    const res = await apiClient.put('/api/visits/doctor-checkup/save', data, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const generateSOAP = async (data) => {
    const res = await apiClient.post('/api/visits/doctor-checkup/generate-soap', data, {})
    if (res) {
        return res
    } else {
        return null
    }
}

export {
    create,
    getVisitFilter,
    getPatientVisit,
    nurseCheckup,
    uploadDokumenPenunjang,
    getDokumenPenunjangByDocId,
    getDokumenPenunjang,
    saveDoctorNote,
    startDoctorCheckUp,
    addAudio,
    generateSOAP,
}